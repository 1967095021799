import React, { useEffect, useRef, useState } from 'react'

import Markdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';
import {LazyImage} from './LazyImage'
import {DATABASE, DoAddUserEmail} from './Firebase'

// import 'firebase/database'

// import 'highlight.js/styles/stackoverflow-dark.css';
// import 'highlight.js/styles/tokyo-night-light.css';

import { Link } from 'react-router-dom';

import { MdKeyboardDoubleArrowRight as Right } from "react-icons/md";
import { IoShareSocial as Share } from "react-icons/io5";
import { GrCompliance as Swoosh } from "react-icons/gr";
import { TbArrowBigUpFilled as Top } from "react-icons/tb";
import { TbArrowBigDownFilled as Down } from "react-icons/tb";
import { FaSquareXTwitter as Twitter } from "react-icons/fa6";

import BlogData from '../Blog.json'
import SubscribeToNewsletter from './SubscribeToNewsletter';


export default function Article({ArticlesData, darkMode}){

    // var [article, article$] = useState(ArticlesData)
    var [content, content$] = useState('')
    var [hrefCopied, hrefCopied$] = useState(false)
    var [windowScrollY, windowScrollY$] = useState(0)
    var [loading, setLoading] = useState(true)
    // var [emailEntered, emailEntered$] = useState(P=>(JSON.parse(localStorage.getItem('email_entered')) !== undefined ? {email:'', done:JSON.parse(localStorage.getItem('email_entered'))} : {email:'',done:false}))

    var [relatedArticles, setRelatedArticles] = useState([])

    // define some colors
    var colors = {
        lt_1:'#F0F2F5',
        lt_2:'#DCE1E3',
        lt_3:'#E0A800',
        lt_4:'#FFA500',
        lt_5:'#A9A9A9',
        lt_6:'#2C3E50',
        lt_7:'#34495E',
        dt_1:'#2C3E50',
        dt_2:'#1C2833',
        dt_3:'#F39C12',
        dt_4:'#E67E22',
        dt_5:'#707B7C',
        dt_6:'#ECF0F1',
        dt_7:'#020202',
    }

    // fetch article
    useEffect(()=>{
        //
        fetch(ArticlesData.path)
        .then(response=>response.text())
        .then(text=>content$(text))
        .catch(error=>console.log('Message:',error))
        //
        window.scrollTo({top:0})
        //
        DoRelatedId()

    },[ArticlesData])

    useEffect(()=>{
        if(content){
            if(document.querySelector('.markdown-content')){
                var markdown_content = document.querySelector('.markdown-content');
                var as = markdown_content.querySelectorAll('a');
                as.forEach(i=>i.target = 'blank')
            }
        }
    },[content])

    //adjust style
    useEffect(()=>{
        const root = document.documentElement;
        const themeLink = document.getElementById('theme-link');

        // change css colors in dark mode
        !darkMode ? root.style.setProperty('--dynamic-color-1', colors.dt_1)&&root.style.setProperty('--dynamic-color-2', colors.dt_2)&&root.style.setProperty('--dynmaic-color-3', colors.dt_3)&&root.style.setProperty('--dynmaic-color-4', colors.dt_4)&&root.style.setProperty('--dynmaic-color-5', colors.dt_5)&&root.style.setProperty('--dynmaic-color-6', colors.dt_6)&&root.style.setProperty('--dynmaic-color-7', colors.dt_7) : root.style.setProperty('--dynamic-color-1',colors.lt_1)&&root.style.setProperty('--dynamic-color-2',colors.lt_2)&&root.style.setProperty('--dynamic-color-3',colors.lt_3)&&root.style.setProperty('--dynamic-color-4',colors.lt_4)&&root.style.setProperty('--dynamic-color-5',colors.lt_5)&&root.style.setProperty('--dynamic-color-6',colors.lt_6)&&root.style.setProperty('--dynamic-color-7',colors.lt_7)

        // change code-tag theme in dark mode
        darkMode ?  themeLink.href = 'https://cdn.jsdelivr.net/npm/highlightjs-themes@1.0.0/tomorrow-night-blue.min.css' : themeLink.href = 'https://cdn.jsdelivr.net/npm/highlightjs-themes@1.0.0/foundation.css'
        
    },[darkMode])

    // scroll
    useEffect(()=>{
        document.addEventListener('scroll',()=>{
            windowScrollY$(window.scrollY)
        })
    },[windowScrollY])
    
    // 
    useEffect(()=>{
        setLoading(false)
    },[])

    // if email is entered in subscribe box
    // useEffect(()=>{
    //     if(emailEntered.done){
    //         localStorage.setItem('email_entered',JSON.stringify(true));
    //     }
    // },[emailEntered.done])


    // function to copy href to clipboard
    var DoCopyToClipboard = () =>{
        navigator.clipboard.writeText(window.location.href);
        hrefCopied$(true)
    }
    // function to open twitter
    var DoTwitterIt = () =>{
        const articleURL = window.location.href;
        const twitterURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(articleURL)}`;
        window.open(twitterURL, '_blank');
    }
    //
    var DoDynamicScroll = () =>{
        windowScrollY <= 100 ? 
            window.scrollTo({
                top:document.body.scrollHeight,
                behavior:'smooth'
            }) 
            : 
            window.scrollTo({
                top:0,
                behavior:'smooth'
            })
    }

    var DoRelatedId = () => {
        // Filter for related articles with common tags but exclude the current article.
        var related = BlogData.filter(
            a => a.id !== ArticlesData.id && a.tags.some(tag => ArticlesData.tags.includes(tag))
        );
    
        // If there are fewer than 3 related articles, add additional articles until we have 3.
        if (related.length < 3) {
            var additional = BlogData.filter(
                a => a.id !== ArticlesData.id && !related.includes(a)
            );
    
            // Combine `related` with additional items to reach at least 3 articles.
            related = related.concat(additional).slice(0, 3);
        } else {
            // Otherwise, take the last 3 related articles.
            related = related.slice(-3);
        }
    
        // Update the state with the selected related articles.
        setRelatedArticles(related);
    }
    

    return( 
        
        <div className={`Article ${!loading && 'fadeIn-article'}`}>

            <div className='scrollToTheTop' onClick={DoDynamicScroll}>{windowScrollY <= 100 ? <Down/> : <Top/>}</div>
            <div className='Article__imgSection'>
                <div className='Article__imgSection_textContainer'>
                    <h2 className='Article__imgSection_title'> {ArticlesData.title} </h2>
                    <h6 className='Article__imgSection_description'>{ArticlesData.description}</h6>
                </div>
                
                {/* <div className='Article__imgSection_imgContainer'> */}
                    {/* <img className='Article__imgSection_img' src={ArticlesData.articleImg} loading='lazy'/> */}
                    <LazyImage alt={ArticlesData.title} parentClass={'Article__imgSection_imgContainer'} className={'Article__imgSection_img'} src={ArticlesData.articleImg} />
                {/* </div> */}
            </div>

            <div className='Article__mainSection'>

                    <div className='Article__mainSectionContainer'>
                            <Markdown className={`markdown-content`} rehypePlugins={[rehypeHighlight, rehypeRaw]} remarkPlugins={[remarkGfm]}>{content}</Markdown>
                    </div>
                    <div className='Article__userOpinionSection'>
                        <div className='Article__userOpinionShareButton'>
                            <a onClick={DoCopyToClipboard} id='copy_button'>
                                { hrefCopied ? 'Copied!' : "Like it? Click to copy page's link!"} 
                                {/* <span id='copy_info'>{hrefCopied  ? 'Copied!' : 'Click to copy'}</span>  */}
                                {hrefCopied ? <Swoosh/> : <Share className='svg-share'/> } 
                            </a> 
                            <span onClick={DoTwitterIt} id='twitter_button'> Or<Twitter className='svg-twitter'/>it </span>
                            {/* <span onClick={DoTwitterIt} id='twitter_button'> twitt it */}
                                {/* <span id='twitter_info'> Or <Twitter className='svg-twitter'/> it  </span>  */}
                            {/* </span> */}
                        </div>
                    </div>
                
            </div>
            <br></br>
{/* 
            <div className='Article__SubscribeToNewsletter'>
                <SubscribeToNewsletter emailEntered={emailEntered} emailEntered$={emailEntered$}/>
            </div> */}

            <div className='Article__secondarySection'>
                <div>
                    <h4> You Got A Moment? Check this out</h4>
                    <ul>
                    {
                    Object.entries(ArticlesData.links).map(([key, value])=>{
                        return(<li key={key}> <a href={value} target='_blank'>{key}</a> </li>)
                    })
                    }
                    </ul>
                </div>
                <div>
                    <Link className='Link' to={'./references'}><h5> You miss the references? I bet they miss you too \_(^-^)_/ See here </h5> </Link>
                </div>
            </div>


            <div className='Article__relatedSection' style={{backgroundColor:darkMode ? 'var(--dt-color-7)' : 'var(--dt-color-6)'}}>
                <div className='Article__relatedSectionTitle'>
                    <h4 className='Article__relatedArticleTitleText'>Related Articles </h4>
                </div>
                <div className='Article__relatedSectionWrapper'>
                    <RelatedArticle ArticlesData={relatedArticles[0]}/>
                    <RelatedArticle ArticlesData={relatedArticles[1]}/>
                    <RelatedArticle ArticlesData={relatedArticles[2]}/>
                </div>
            </div>

            
            <div className='Article__nextArticle'>
                <Link className='Link' to={`/blog/article/${BlogData[ArticlesData.id != BlogData.length ? ArticlesData.id : 0].title.replace(/ /g, '_').toLowerCase()}`} >Next Article <Right className='next-article-right'/> </Link>
            </div>
        </div>
    )
}

export function RelatedArticle({ArticlesData}){
    var [article, setArticle] = useState({id:"",title:"a",img:""})
    useEffect(()=>{
        if(ArticlesData != undefined) {setArticle(ArticlesData)}
    },[ArticlesData])
    var loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit."

    // useEffect(()=>{console.log(`/blog/article/${ArticlesData.title.replace(/ /g, '_').toLowerCase()}`)},[])
    return(
        <Link className='Article__relatedArticle Link' to={`/blog/article/${article.title.replace(/ /g, '_').toLowerCase()}`}>
            <div className='Article__relatedArticleImgContainer'>
                <img className='Article__relatedArticleImg' src={article.img} alt='related article'/>
            </div>
            <div className='Article__relatedArticleTextContainer'>
                <p className='Article__relatedArticleText'>
                    {article.title|| loremIpsum}
                </p>
            </div>
        </Link> 
    )
}