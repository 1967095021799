import React from "react";

import { SlSocialGithub as Cat } from "react-icons/sl";
import { FaXTwitter as Twitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer ({darkMode}){

    var logoText = "©Ixtix 2024"
    return(

        <div className="Footer" style={darkMode ? {boxShadow:'none'} : {}}>
            <div className="Footer__logoContainer">
                <p className="Footer_logoText" style={darkMode ? {color:'var(--dt-color-6)'} : {}}>{logoText}</p>
            </div>
            <div className="Footer__socialMedia">
                <Link className="Footer__socialMedia1 Link" style={darkMode ? {color:'var(--dt-color-6)'} : {}} to={'https://github.com/bugixtix'} target="_blank" title="Github link">
                    <Cat/>
                </Link>
                <Link className="Footer__socialMedia2 Link" style={darkMode ? {color:'var(--dt-color-6)'} : {}} to={'https://twitter.com/_ixtix_'} target="_blank" title="Twitter link">
                    <Twitter/>
                </Link>
            </div>
        </div>
    )
}