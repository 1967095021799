import React, {useState} from 'react'
import placeholder from '../images/placeholder.png'
import '../styles/Gallery.css'
export function LazyImage({src, alt, className,parentClass}){

    const [loaded, setLoaded] = useState(false);

    parentClass = parentClass == undefined ? 'standardImgParent' : parentClass


    return(

        <div className={parentClass}>
            {/* Placeholder */}
            <img
                src={placeholder}
                alt={alt}
                className={className}
                style={{display:loaded ? 'none' : 'flex'}}
            />
            {/* Real Image */}
            <img
                src={src}
                alt={alt}
                className={className}
                onLoad={()=>setLoaded(true)}
                style={{display:loaded ? 'flex' : 'none'}}
            />
        </div>
    )
}