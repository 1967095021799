import React from 'react'


export default function References ({Article, darkMode}){

    var GetId = (index) =>{
        var id = ''

        if(index % 2 === 0) {
            id = 'evenRow'
            // if(darkMode){
            //     id= 'oddRow'
            // }
        }else if(index % 2 !== 0){
            id = 'oddRow'
            // if(darkMode){
            //     id = 'evenRow'
            // }
        }
        return id
    }

    return(
        <div className='References'>
            
            <h2>References</h2>
            <div>

            <table>
                <thead>
                    <tr>
                        <th>
                            Reference
                        </th>
                        <th>
                            Website
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        Object.keys(Article.references).map((key,index)=>(
                            <tr key={key} id={GetId(index)}>
                                <td>
                                    {key}
                                </td>

                                <td>
                                    <a href={Article.references[key]}> {Article.references[key]} </a>
                                </td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
            </div>

        </div>
    )
}