
import React, { useEffect, useState } from "react";

import GalleryPictures from '../Gallery.json'
import { IoClose as Close} from "react-icons/io5";
import { MdOutlineKeyboardDoubleArrowLeft as Left } from "react-icons/md";
import { MdKeyboardDoubleArrowRight as Right } from "react-icons/md";
import { LazyImage } from "./LazyImage";
import { SlControlStart } from "react-icons/sl";
import placeholder from '../images/placeholder.png'

export default function Gallery ({darkMode}){
    var [selectedImage, selectedImage$] = useState(placeholder)
    var [fadeImage, fadeImage$] = useState(true)
    var [fadePopup, fadePopup$] = useState(false)
    var [loading, loading$] = useState(true)

    var DoOpenImage = (image) =>{
        selectedImage$(image)
        DoFadePopup('in')
    }
    var DoCloseImage = () =>{
        selectedImage$('true')
        DoFadePopup('out')
    }
    var DoFadePopup = (mode) =>{
        var DoExecute = () =>{
            fadePopup$(()=>(mode === 'in' ? true : false))
        }
        fadePopup$(()=>(mode==='in' ? false : true));
        setTimeout(DoExecute,0)
        return ()=>{clearTimeout(DoExecute)}
    }
    var DoChangeImage = (direction) =>{
        var DoNext = () =>{
            var DoExecute = () =>{
                selectedImage$((p)=>(p.id === GalleryPictures.length-1 ? GalleryPictures[0] : GalleryPictures[p.id + 1]))
                fadeImage$(true)
            }
            fadeImage$(false);
            setTimeout(DoExecute,0)
            return ()=>{clearTimeout(DoExecute)}
        }
        var DoPrevious = () =>{
            var DoExecute = () =>{
                selectedImage$((p)=>(p.id === 0 ? GalleryPictures[GalleryPictures.length-1] : GalleryPictures[p.id - 1]));
                fadeImage$(true);
            }
            fadeImage$(false);
            setTimeout(DoExecute,0)
            return ()=>{clearTimeout(DoExecute)}
        }
        direction == "next" ? 
         DoNext() : 
        direction == "previous" ?
         DoPrevious() :
        console.log("__ DoChangeImage")
    }
    //
    useEffect(()=>{
        var timer = setTimeout(()=>{loading$(false)},0)
        return ()=>clearTimeout(timer)
    },[])
    //
    var DoEscape = (event) =>{
        if(event.key==="Escape") DoCloseImage();
    }
    useEffect(()=>{
        document.body.addEventListener('keyup',DoEscape)

        return ()=>{ document.body.removeEventListener('keyup', DoEscape)}
    },[])

return(
    <div className={`Gallery`}>
        <div className={`Gallery__container ${fadePopup? "hide-pictures" : "show-pictures"}`}>
            {
                GalleryPictures.map(picture=>(
                    <div className="Gallery__itemContainer" key={picture.id} onClick={()=>DoOpenImage(picture)}>
                        {/* <img src={picture.img} className="Gallery__item" loading="lazy" /> */}
                        <LazyImage src={picture.img} className="Gallery__item" alt="Gallery's picture" parentClass="lazyImage-parent"/>
                        <span className="Gallery__itemTitle">
                            <span>{picture.title || 'IXTIX Gallery'}</span>
                        </span>
                    </div>    
                    ))
            }
        </div>

        <div className={`Gallery__popup ${fadePopup ? 'show-popup' : 'hide-popup'}`}>
            <div className={`Gallery__popupContent`}>
                <div className="Gallery__popupFirstSection">
                    <Close className="Gallery__closeSvg" onClick={DoCloseImage}/>
                </div>
                <div className="Gallery__popupSecondSection">
                    <Left className="Gallery__leftSvg" onClick={()=>DoChangeImage("previous")}/>
                    
                    <img alt={selectedImage.title} src={selectedImage.img} className={`Gallery__popupItem ${fadeImage ? 'popup-fadein' : 'popup-fadeout'} `} />
                    {/* <LazyImage src={selectedImage.img} className={"Gallery__popupItem"}/> */}

                    
                    <Right className="Gallery__rightSvg" onClick={()=>DoChangeImage("next")}/>
                </div>
            </div>
        </div>

    </div>
)
}