import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import development from '../images/development.png'
import naruto from '../images/naruto.png'
import { FaChevronLeft as Left } from "react-icons/fa";
import { FaChevronRight as Right} from "react-icons/fa";
import { RxSlash as Slash} from "react-icons/rx";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { SlSocialGithub as Cat } from "react-icons/sl";
import { LazyImage } from "./LazyImage";

import pfp from '../images/pfp.jpg'

import TypingEffect from "./UseTypingEffect";

import BlogData from '../Blog.json'


export default function Main({darkMode}){

    var title = "Hi there!"
    var description = "I'm Ixtix, an active web developer and content maker with passion for clean code and user-friendly designs \\_(^_^)_/"
    var btnText1 = "Take A Look at My Work on Github"
    var btnText2 = "Read My Last Article >>"
    var [introText, setIntroText] = useState("Read My Last Article >>")
    var [loading, loading$] = useState(true)
    var [screenSize, setScreenSize] = useState(window.innerWidth)
    var [lastArtLink, setLastArtLink] = useState("#")
    
    useEffect(()=>{
        var lastArtLink_ = "./blog/article/" + BlogData[BlogData.length - 1].title.replace(/ /g, "_").toLowerCase()
        setLastArtLink(lastArtLink_)
        loading$(false)
    },[])

    // function DidResize to check if the width of the current screen is larger than 600 px ( if not, do not show my home main icon)
    function DidResize(){
        setScreenSize(window.innerWidth)
    }

    // 
    useEffect(()=>{
        window.addEventListener('resize', DidResize)
        if(screenSize < 600){
            setIntroText("Read My Last Article >")
        }
        return ()=>window.removeEventListener('resize', DidResize)

    },[screenSize])

    return(

        <div className={`Main ${!loading && 'fadeIn-start'}`}>

            <div className="Main__container0" style={darkMode ? {boxShadow:'none'} : {}} >

            <div className={`Main__container0Background ${darkMode && 'Main__darkBackground'}`} >
            </div>

            <div className="Main__container1">

                {/* <img src={development} alt="Main image"  className="Main__image"/> */}

                <div className="Main__container11">

                <div className="Main__firstCodeslash">
                    <Left className="CodeslashLeft"/>
                        <h2 className="Main__codeslashText" style={darkMode ? {color:'var(--white)'} : {}}> body</h2>
                    <Right className="CodeslashRight"/>
                </div>



                <div className="Main__container111">
                    <Left className="Codeslash leftIcon"/>
                    <div className="Main__intro">
                        <h2 className="Main__title" style={darkMode ? {color:'var(--white)'} : {}} > {title}</h2>
                        <h6 className="Main__description" style={darkMode ? {color:'var(--white)'} : {}}> {description}</h6>
                        {/* <Link id="Link" to={'https://github.com/bugixtix'} className="Main__aboutButton" target="_blank">{btnText1} <Cat/> </Link> */}
                    </div>
                    <Slash className="Codeslash slashIcon"/>
                    <Right className="Codeslash rightIcon"/>
                </div>

                <div className="Main__thirdCodeslash">
                    <Left className="CodeslashLeft"/>
                    <h2 className="Main__codeslashText" style={darkMode ? {color:'var(--white)'} : {}}> body </h2>
                    <Slash className="CodeslashSlash"/>
                    <Right className="CodeslashRight"/>
                </div>
                </div>
                    {/* <Logo darkMode={darkMode}/> */}
                    {/* <div>
                    <img src="https://www.github.com/bugixtix.png" alt="Profile picture" className="main__pfp"/>
                    </div> */}
                    <LazyImage src={pfp} alt="Profile picture" className="main__pfp" parentClass={"Main__imageParent"}/>
            </div>


            <div className="Main__container2">
                <Link to={lastArtLink} className="Main__contactButton Link"> {introText}</Link>
            </div>

            </div>

        </div>
    )
}

function Logo({darkMode}){

    var logoText = 'IXTIX';
    var logoSlogan = 'WHERE IDEAS MEET CODE';
    return(

        <div className="main-logo">
            <div className="mainLogo_container1">
                <img src="/atom.png" loading="lazy"/>
            </div>
            <div className="mainLogo_container2">
                <p className="mainLogoText" > {logoText}</p>
                {/* <p className="mainLogoSlogan" >{logoSlogan}</p> */}
                <TypingEffect/>
            </div>
        </div>
    )

}